import { Route, Routes } from 'react-router-dom';

import Home from './routes/Home';
import Accordion from './routes/Accordion';

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/accordion" element={<Accordion />} />
			</Routes>
		</>
	);
}

export default App;
