import { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './HeaderStyles.css';
import logo from '../../assets/icon.png';

const Header = () => {
	return (
		<header className="header">
			<div className="header-left">
				<a className="header-logo mb-md-0 me-md-auto" href="/">
					<img src={logo} alt="logo" />
				</a>
			</div>

			<div className="header-right" style={{}}>
				<h1>Deephow Demo</h1>
			</div>
		</header>
	);
};

export default Header;
