import 'bootstrap/dist/css/bootstrap.min.css';
import './FooterStyles.css';
import logo from '../../assets/its-logo.png';

const Footer = () => {
	return (
		<footer>
			<div class="footer-left">
				<p>Powered By:</p>
				<a class="footer-logo" href="http://www.its-na.com/">
					<img src={logo} alt="logo" />
				</a>
			</div>
		</footer>
	);
};
export default Footer;
