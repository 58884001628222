import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Breadcrumb } from 'react-bootstrap';
import { TbStairs } from 'react-icons/tb';
import './HomeStyles.css';
import image1 from '../assets/deephow_image.jpg';

import React from 'react';

const Home = () => {
	const link = 'https://deephow.ai/p/ZJ6gyb02is3vLNyMfVdu';
	return (
		<>
			<Header />

			<main class="home" style={{}}>
				<Breadcrumb style={{ position: 'relative', top: '18%', left: '2%' , width: "70%"}}>
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item href="/">Training Videos</Breadcrumb.Item>
				</Breadcrumb>
				<div class="wrapper" style={{}}>
					<div class="link-container">
						<a href={link}>
							<div class="main-link">
								<img src={image1} style={{ width: '100%', height: '100%', borderRadius: '1rem' }}></img>
							</div>
						</a>
						<h3 style={{ textAlign: 'center' }}>Link to DeepHow</h3>
					</div>
					<div class="link-container">
						<a href="/accordion">
							<div class="main-link">
							<iframe style={{ height: '100%' }} class="video-window" width="100%"  src="https://deephow.ai/p/ZJ6gyb02is3vLNyMfVdu" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
							<h3 style={{ textAlign: 'center' }}>Embeded Link</h3>
						</a>
					</div>
				</div>
				<Footer />
			</main>
		</>
	);
};

export default Home;
